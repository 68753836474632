
//公共库
import { Message } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import DTCls from "@/utils/datetime";
import { saveAs } from "@/api/request/base";
import LineChart from "@/comp/Charts/LineChart.vue";
import Pagination from "@/comp/Pagination/index.vue";
import { getActiveUserList, exportActiveUserList, getUserActive, exportUserActive } from "@/api/request/bigdata";

//组件
@Component({
  name: "ActiveUser",
  components: {
    LineChart,
    Pagination,
  },
})

//代码
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private dateList: any[] = [];
  private userList: any[] = [];
  private listLoading: boolean = true;
  private userChecked: boolean = false;
  private initValue: any = { type: "charts" };

  //请求数据
  private listQuery: any = {
    //临时数据
    time: [],

    //请求数据
    type: 0,
    internal: 0,
    end_time: "",
    start_time: "",
  };

  //时间参数
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
    };
  }

  //用户类型
  private typeList: any[] = [
    { type: 0, name: "全部" },
    { type: 1, name: "全部用户" },
    { type: 2, name: "博主" },
    { type: 3, name: "男用户" },
    { type: 4, name: "女用户" },
  ];

  //创建
  created() {
    //初始化时间
    this.initTime();

    //获取数据
    this.getList();
  }

  //获取数据
  private async getList() {
    //判断显示
    if (!this.listQuery.time || this.listQuery.time.length != 2) return Message({ message: "请选择日期！", type: "error", duration: 5 * 1000 });

    //显示等待
    this.listLoading = true;

    //数据赋值
    this.userList = [];
    this.dateList = [];
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.start_time = `${this.listQuery.time[0]} 00:00:00`;
      this.listQuery.end_time = `${this.listQuery.time[1]} 23:59:59`;
    } else {
      this.listQuery.start_time = "";
      this.listQuery.end_time = "";
    }

    //获取数据
    const { data } = await getUserActive(this.listQuery);

    //数据赋值
    this.list = data.active_date;
    this.activeMessage.total = data.total_num;
    this.activeMessage.average = data.average_num;

    //列表赋值
    if (this.list.length) {
      this.list.forEach((item: any) => {
        this.dateList.push(item.date);
        this.userList.push(item.num);
      });
    }

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //初始化时间
  private initTime(): void {
    //数据赋值
    this.listQuery.end_time = `${DTCls.getCurDateTimeYMD()}`;
    this.listQuery.start_time = `${DTCls.getCurDateTimeYMD()}`;
    this.listQuery.time = [`${this.listQuery.start_time}`, `${this.listQuery.end_time}`];
  }

  //处理重置查询
  private handleReset(): void {
    //数据赋值
    this.listQuery.type = 0;
    this.listQuery.internal = 0;

    //初始化时间
    this.initTime();

    //获取数据
    this.getList();
  }

  //处理查询
  private handleFilter(): void {
    //获取列表
    this.getList();
  }

  //处理导出
  private async handleExcel() {
    //判断显示
    if (!this.listQuery.time || this.listQuery.time.length != 2) return Message({ message: "请选择日期！", type: "error", duration: 5 * 1000 });

    //显示等待
    this.listLoading = true;

    //数据赋值
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.start_time = `${this.listQuery.time[0]} 00:00:00`;
      this.listQuery.end_time = `${this.listQuery.time[1]} 23:59:59`;
    } else {
      this.listQuery.start_time = "";
      this.listQuery.end_time = "";
    }

    //获取数据
    const { data } = await exportUserActive({ ...this.listQuery, excel: true });

    //保存数据
    saveAs(data, `${this.listQuery.start_time} - ${this.listQuery.end_time} 活跃用户`);

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //修改内部
  private checkChange(val: any): void {
    //数据赋值
    this.listQuery.internal = val ? 1 : 0;

    //获取数据
    this.getList();
  }

  //点击索引
  private async indexClick(index: any) {
    //判断返回
    if (!this.userList[index]) return this.$message.error("该日人数为空！");

    //数据赋值
    this.checked = false;
    this.activeParams.internal = 0;
    this.activeParams.date = this.dateList[index];
    this.activeParams.type = this.listQuery.type;

    //显示对话框
    this.dailogVisible = true;

    //获取数据
    this.getActiveList();
  }

  //-------------------------------- 活跃用户列表 --------------------------------
  //定义变量
  private activeInfo: any = {};
  private activeList: any[] = [];
  private activeTotal: number = 0;
  private checked: boolean = false;
  private dailogLoading: boolean = false;
  private dailogVisible: boolean = false;
  private activeMessage: any = { total: 0, average: 0 };

  //请求数据
  private activeParams: any = {
    //页码数据
    row: 8,
    page: 1,

    //请求数据
    date: "",
    internal: 0,
    type: this.listQuery.type,
  };

  //取消按钮
  private btnCancel(): void {
    //隐藏对话框
    this.dailogVisible = false;
  }

  //导出Excel
  private async activeExcel() {
    //显示等待
    this.dailogLoading = true;

    //获取数据
    const { data } = await exportActiveUserList({ ...this.activeParams, excel: true });

    //保存数据
    saveAs(data, `${this.listQuery.start_time} - ${this.listQuery.end_time} 活跃用户`);

    //隐藏等待
    setTimeout(() => {
      this.dailogLoading = false;
    }, 0.5 * 1000);
  }

  //获取数据
  private async getActiveList() {
    //显示等待
    this.dailogLoading = true;

    //获取数据
    const res = await getActiveUserList({ excel: false, ...this.activeParams });

    //数据赋值
    this.activeInfo = res.data;
    this.activeList = res.data.users;
    this.activeTotal = res.data.total;

    //隐藏等待
    setTimeout(() => {
      this.dailogLoading = false;
    }, 0.5 * 1000);
  }

  //修改内部
  private userCheckChange(val: any): void {
    //数据赋值
    this.activeParams.internal = val ? 1 : 0;

    //获取数据
    this.getActiveList();
  }
}
